<template>
    <v-dialog max-width="500px"  v-model="show">
    <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
          <v-toolbar flat dark color="panel1">
            <v-toolbar-title>Add New User</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="show=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <div class="pa-5">

                            <v-row no-gutters>
                    <v-col cols="12">

                <v-form ref="mainForm">
                    <v-text-field 
                        v-model="form.user_login_id" label="Login ID" :rules="inputRules" 
                        outlined                          
                    ></v-text-field>
                    <v-text-field v-model="form.password" label="Password" type="password" :rules="inputRules" outlined></v-text-field>
                    <v-text-field v-model="form.full_name" label="Full Name" :rules="inputRules" outlined></v-text-field>
                    <v-select
                        v-model = "form.user_type"
                        :items="userTypeList"
                        item-text="description"
                        item-value="code"
                        label="User Type"
                        standard
                        :rules="inputRules"
                        outlined
                    ></v-select>

                    <v-spacer></v-spacer>
                </v-form>
                    </v-col>
                            </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>

          </v-card>
    </v-dialog>

</template>

<script>
import DlgMessage from '@/components/DlgMessage'
import ApUserService from "@/services/ApUserService"
import ApParameterService from "@/services/ApParameterService"
export default {
    name: "DlgUserAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
    },
    data(){
        return{
            dialog: false,
            form:{
                login_id: "",
                admin_user_id: 0,
                user_login_id:"",
                full_name: "",
                password: "",
                status: "ACTIVE"
            },
            userTypeList:[],
            error_message:'',
            error_code: '200',
            inputRules: [
             v => !!v || 'This field is required'
            ]
        }
    },
    async created() {
        this.getParameter();
    },
     
    methods:{
   dlgShow(){
    },
    dlgClose(){
        console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.c_code = "";
            this.form.c_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },                
        async save(){
            console.log(this.form);
            if(this.$refs.mainForm.validate()) {
                try{
                
                    ApUserService.insert_user(this.form).then(() => {
                        //console.log(res);
                        this.$refs.dlgMsg.showDlgBox(true,'success','New User','Success.');
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','New User',e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','New User','Unknown Error');
                    
                } 
            }

        },
        async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"add-admin_user"}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.userTypeList = res.data.userTypeList;
                    console.log(res.data);
                    
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },

       
    }
}    

</script>